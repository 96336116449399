<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Список для перевода
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-4">
                <b-form-group>
                  <template v-slot:label>
                    Инн
                  </template>
                  <b-form-input
                      v-model="filter.inn"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-12">
                <b-button type="button" @click="reloadData" variant="primary">{{
                    $t("common.filter")
                  }}
                </b-button>
              </div>
            </div>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("studentsList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.firstName") }}
                </th>
                <th class="text-left">{{ $t("studentsList.name") }}</th>
                <th class="text-left">
                  {{ $t("studentsList.patronymic") }}
                </th>
                <th class="text-left">
                  Школа
                </th>
                <th class="text-left">
                  {{ $t("studentsList.contactPhone") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in students" :key="item.id">
                <td>{{ k + 1 }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.patronymic }}</td>
                <td>{{ item.schoolName }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>
                  <button class="btn btn-success" @click="transferStudent(item)">
                    Перевести
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
    
    <b-modal id="student-transfer-modal" ref="student-transfer-modal">
      <template v-slot:modal-title>
        Перевести
      </template>
      <b-form>
        <div class="alert alert-primary">
          {{ transferStudentName }}
        </div>
        <b-form-group>
          <template v-slot:label>
            Номер приказа
          </template>
          <b-form-input
              v-model="transferForm.transferOrderNumber"
              :state="studentTransferModelStates.transferOrderNumber"
          ></b-form-input>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferOrderNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Дата
          </template>
          <b-form-datepicker
              v-model="transferForm.transferDate"
              :state="studentTransferModelStates.transferDate"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Причина
          </template>
          <b-form-select
              v-model="transferForm.transferReasonId"
              :options="transferReasons"
              :state="studentTransferModelStates.transferReasonId"
          ></b-form-select>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.transferReasonId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Группа
          </template>
          <b-form-select
              v-model="transferForm.groupId"
              :options="transferGroups"
              :state="studentTransferModelStates.groupId"
          ></b-form-select>
          <div class="invalid-feedback">{{ studentTransferModelSaveError.groupId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Откуда прибыл
          </template>
          <b-form-textarea
              v-model="transferForm.transferNote"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveTransferStudent" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeTransferForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
    
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
export default {
  name: "TransferList",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        inn: ""
      },
      students:[],
      transferStudentName: '',
      transferForm:{
        studentToGroupId: null,
      },
      studentTransferModelStates:{},
      studentTransferModelSaveError:{},
      transferReasons:[],
      transferGroups:[],
    };
  },
  mounted() {
    ApiService.querySecured("dictionaries/transferReasons").then(({data}) => {
      this.transferReasons = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/groupsForTransfer").then(({data}) => {
      this.transferGroups = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    reloadData(){
      let $this = this;
      ApiService.postSecured("students/forTransferList", this.filter).then(({data}) => {
        $this.students = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    transferStudent(student) {
      this.transferForm = {
        studentToGroupId: student.studentToGroupId,
      };
      this.transferStudentName = student.firstName + " " + student.name;
      this.$refs["student-transfer-modal"].show();
    },
    saveTransferStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/transfer", this.transferForm)
          .then(function () {
            $this.$refs["student-transfer-modal"].hide();

            $this.reloadData();
            $this.transferForm = {};
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.studentTransferModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentTransferModelStates[i] = false;
              }
            } else {
              $this.studentDropModelSaveError = response.data;

              for (let i in response.data) {

                $this.studentTransferModelSaveError[i] = false;
              }
            }
          });
    },
    closeTransferForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.transferForm = {};

      this.$refs["student-transfer-modal"].hide();
    },
  }
};
</script>
